import React from 'react';
import { makeStyles } from '@material-ui/core';

type Props = {
  text: string;
  columnVersion?: boolean;
  className?: string;
};

export const CardNotAdded = ({ text, columnVersion, className }: Props) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.containerCard} ${
        columnVersion && classes.column
      } ${className}`}
    >
      <img src="https://storage.googleapis.com/rokinapp-images/MyFinances/myAccount/Astronaut%201.svg" />
      <span>{text}</span>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  containerCard: {
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    padding: '1rem 0.5rem',
    borderRadius: 10,
    fontSize: '0.75rem',
    '& span': {
      color: '#959595',
    },
  },
  column: {
    flexDirection: 'column',
  },
}));
