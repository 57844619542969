import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  monthBalance: {
    boxShadow: '5.3px 5.3px 10.5px 2.6px rgba(0, 0, 0, 0.2)',
    borderRadius: 15,
    padding: '1rem 2rem',
    background: '#F4F3F3',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    '& .addIcon': {
      cursor: 'pointer',
      height: '3.5rem',
      width: '3.5rem',
      color: '#008296',
    },
    [theme.breakpoints.down(1240)]: {
      marginTop: '-5rem',
      width: '90%',
      margin: '0 auto',
    },
  },
  title: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    '& span': {
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#6B6B6B',
    },
    '& .infoIcon': {
      color: '#008296',
      height: 20,
    },
  },
  grid: {
    display: 'grid',
    width: '300px',
    '& .amount': {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      color: '#0A5669',
      fontSize: '1.75rem',
    },
  },
}));

export default useStyles;
