import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerAll: {
    background: '#eaeaea',
    maxWidth: '57.7rem',
    margin: '0 auto',
    width: '100%',
    paddingTop: '1rem',
    [theme.breakpoints.up(1300)]: {
      paddingBottom: '2rem',
      borderRadius: '18px',
    },
    '& .titleCon': {
      color: '#282828',
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 600,
      [theme.breakpoints.up(1300)]: {
        paddingBottom: '2rem',
        borderRadius: '18px 18px 0 0 ',
        textAlign: 'center',
      },
    },
  },
  line: {
    width: '90%',
    maxWidth: '22rem',
    borderBottom: '1px solid #d1d1d1',
    height: '1rem',
    padding: '0.5rem 0',
    opacity: 0.6,
    margin: '0 auto',
    [theme.breakpoints.up(1300)]: {
      display: 'none',
    },
  },
  container: {
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.up(1300)]: {
      borderRadius: '0 0 18px 18px',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
  },
  background: {
    minHeight: '100vh',
    padding: '2rem',
  },
  heightCardNotAdded: {
    height: '10rem',
    width: '100%',
    margin: '0 auto',
    maxWidth: '45.7rem',
    [theme.breakpoints.up(1300)]: {
      maxWidth: '51.875rem',
    },
  },
  containerRules: {
    background: '#FFFFFF',
    borderRadius: 13,
    marginTop: '1rem',
    [theme.breakpoints.up(1300)]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      background: 'none',
      gap: '1rem 0',
    },
  },
}));

export default useStyles;
