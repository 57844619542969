import { ButtonOutlined, ButtonPrimary } from '@components/Reusables';
import { parseNumber } from '@utils';
import { FLAG_LIGHT_GREEN_ICON, WALLET_ICON } from 'images/GCP';
import React from 'react';
import useStyles from './HeaderPrincipalSection.styles';
import AddIcon from '@material-ui/icons/Add';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';

interface props {
  amount: number;
  onNewRuleClick: () => void;
}

const HeaderPrincipalSection = ({ amount, onNewRuleClick }: props) => {
  const classes = useStyles();
  return (
    <section className={classes.containerAll}>
      <div className={classes.container}>
        <div className={classes.containerTop}>
          <img src={WALLET_ICON} />
          <div className={classes.data}>
            <span className="title">Haz sumado</span>
            <span className="amount">${parseNumber(amount, 2)}</span>
          </div>
        </div>
        <div className={classes.buttons}>
          <ButtonOutlined
            className="width"
            text="Ver objetivos"
            startIcon={<img src={FLAG_LIGHT_GREEN_ICON} />}
            onClick={() => navigate(Route.investments + '/?#objectives')}
          />
          <ButtonPrimary
            className="width"
            text="Nueva regla"
            startIcon={<AddIcon />}
            onClick={onNewRuleClick}
          />
        </div>
      </div>
    </section>
  );
};

export default HeaderPrincipalSection;
