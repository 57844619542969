import { ButtonPrimary } from '@components/Reusables';
import { MONEY_ICON } from '@images';
import { PeopleAltOutlined, PeopleOutline } from '@material-ui/icons';
import { parseNumber } from '@utils';
import { ALERT_GREEN_ICON, PEOPLE_WHITE_ICON } from 'images/GCP';
import React from 'react';
import useStyles from './CardSavingRules.styles';

interface props {
  name?: string;
  amount?: number;
  disabled?: boolean;
  noinvest?: boolean;
}

const CardSavingRules = ({ name, amount, disabled, noinvest }: props) => {
  const classes = useStyles();
  return (
    <section className={classes.containerAll}>
      <div className={classes.containerTop}>
        <div className="left">
          <span className="title">{name}</span>
          <span className="amount">${parseNumber(amount || 0, 2)}</span>
          {disabled && <span className="rule">Regla desactivada</span>}
        </div>
        <div className="right">
          <img src={ALERT_GREEN_ICON} />
          <span className="title">Programada</span>
        </div>
      </div>
      <div className={classes.line} />
      <div className={classes.containerBottom}>
        <span className="spanContainer">
          Para que tus reglas comiencen a funcionar, solo debes configurar tu
          {noinvest ? ' perfil de inversión' : ' pago automático (PAC)'}
        </span>
        <ButtonPrimary
          text={noinvest ? 'Completar perfil de inversión' : 'Configurar PAC'}
          onClick={() => {}}
          className={classes.button}
          startIcon={<img src={noinvest ? PEOPLE_WHITE_ICON : MONEY_ICON} />}
        />
        <span className={classes.bottomText}>
          Esto solo tendrás que hacerlo 1 vez, y luego todas tus transacciones
          serán automáticas ;)
        </span>
      </div>
    </section>
  );
};

export default CardSavingRules;
