import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core';
import { SEO } from '@components';
import HeaderPrincipalSection from '@components/SmartSave/HeaderPrincipalSection/HeaderPrincipalSection';
import CompleteInvestments from '@components/SmartSave/CompleteInvestments/CompleteInvestments';
import SavingRules from '@components/SmartSave/SavingRules/SavingRules';
import { Route } from '@interfaces';

interface props {
  setDetail?: () => void;
}

const SmartSave = ({ setDetail }: props) => {
  const classes = useStyles();

  const [isSelect, setSelect] = useState(0);

  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);
  const infoButtonHandler = () => {
    setOpenInfoPopUp(!openInfoPopUp);
  };
  // 0 no investments - 1 sin PAC - 2 list of savings rules
  const stepBase = 1;

  const [step, setStep] = useState(stepBase);
  const noinvest = true;
  const [isEdit, setEdit] = useState(false);
  const [dataRule, setDataRule] = useState({
    title: '',
    subtitle: '',
    amountMonth: 0,
    amountSaved: 0,
    titleHeader: '',
    icon: '',
    frequency: '',
    countTransactions: 0,
    numberCard: 0,
  });

  return (
    <>
      <SEO
        title="Rokin - Centro de Ayuda"
        description="¿Tienes dudas, comentarios o sugerencias? Encuentra preguntas frecuentes y tips para aprovechar al máximo tu cuenta Rokin."
      />
      {step === 0 && (
        <div className={classes.background}>
          <HeaderPrincipalSection
            onNewRuleClick={() => navigate(Route.createNewRule)}
            amount={98768.5}
          />
          <CompleteInvestments />
          <SavingRules className={classes.savingRulesDesktop} noRules />
        </div>
      )}{' '}
      {/* dashboars sin PAC*/}
      {step === 1 && (
        <div className={classes.background}>
          <HeaderPrincipalSection
            onNewRuleClick={() => navigate(Route.createNewRule)}
            amount={98768.5}
          />
          <SavingRules noinvest className={classes.titleCon} savingRules />
        </div>
      )}
      {/* smartSave dashboard edit
       */}
      {step === 2 && (
        <div className={classes.background}>
          <HeaderPrincipalSection
            onNewRuleClick={() => navigate(Route.createNewRule)}
            amount={98768.5}
          />
          <SavingRules
            className={classes.titleCon}
            setEdit={() => {
              navigate(Route.smartSaveDetails);
            }}
            cardsRules
          />
        </div>
      )}
      {/* trip amsterdam
       */}
    </>
  );
};

export default SmartSave;

const useStyles = makeStyles((theme) => ({
  background: {
    background: '#eaeaea',
    minHeight: '90vh',
    [theme.breakpoints.up(1300)]: {
      background: '#00323c',
      minHeight: '100vh',
    },
  },
  savingRulesDesktop: {
    [theme.breakpoints.up(1300)]: {
      borderRadius: '0 0 18px 18px',
    },
  },
  heightCardNotAdded: {
    height: '10rem',
    width: '90%',
    margin: '0 auto',
    maxWidth: '45.7rem',
  },
  disabled: {
    [theme.breakpoints.down(1300)]: {
      '& header': {
        display: 'none',
      },
      '& >div': {
        marginTop: 0,
      },
    },
    '& >div': {
      marginTop: 13,
      '& >div >div >section >div': {
        background: '#00323c',
      },
    },
  },
  titleCon: {
    '& .titleCon': {
      [theme.breakpoints.up(1300)]: {
        paddingBottom: '0',
        paddingTop: '1rem',
      },
    },
  },
  disabledMobile: {
    [theme.breakpoints.up(1300)]: {
      '& >header': {
        display: 'none',
      },
      '& >div >section >div section:nth-child(2)': {
        margin: '0 auto',
        gap: 0,
      },
    },
  },
}));
