import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerAll: {
    background: '#eaeaea',
    width: '100%',
    margin: '0 auto',
    maxWidth: '57.7rem',
    color: '#646464',
    padding: '1.5rem 0',
    borderRadius: '18px 18px 0 0',
  },
  container: {
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.up(1300)]: {
      display: 'flex',
      width: '90%',
      justifyContent: 'space-between',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem 0',
    '& span': {
      color: '#ffffff',
    },
    '& svg': {
      color: '#ffffff',
    },
    '& button': {
      borderRadius: 10,
    },
  },
  containerTop: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '19.5rem',
    width: '100%',
    margin: '0 auto',
    gap: '1rem',
    alignItems: 'center',
    [theme.breakpoints.up(1300)]: {
      margin: '0 0 0 0rem',
      alignItems: 'start',
    },
    '& .subtitle': {
      fontFamily: 'Nunito',
      fontSize: '0.75rem',
      color: '#646464',
    },
    '& .title': {
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 600,
      color: '#282828',
    },
  },
  data: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
