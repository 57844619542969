import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerAll: {
    background: '#00323c',
    width: '100%',
    margin: '0 auto',
    color: '#ffffff',
    padding: '1.5rem 0',
    maxWidth: '100%',
  },
  container: {
    width: '90%',
    margin: '0 auto',
    maxWidth: '57.7rem',
    [theme.breakpoints.up(1300)]: {
      background: '#001e28',
      borderRadius: 30,
      width: '100%',
      display: 'flex',
      height: '10rem',
      alignItems: 'center',
    },
  },
  buttons: {
    display: 'flex',
    gap: '0.5rem',
    width: '22rem',
    margin: '1rem auto 0',
    [theme.breakpoints.up(1300)]: {
      justifyContent: 'end',
      margin: '0',
      marginRight: '4rem',
      '& .width': {
        minWidth: 150,
      },
    },
    '& span': {
      color: '#ffffff',
    },
    '& svg': {
      color: '#ffffff',
    },
    '& button': {
      borderRadius: 50,
      width: 150,
    },
  },
  containerTop: {
    display: 'flex',
    width: '200px',
    margin: '0 auto',
    gap: '1rem',
    alignItems: 'center',
    [theme.breakpoints.up(1300)]: {
      marginLeft: '4rem',
      width: '255px',
    },
    '& .title': {
      fontFamily: 'Montserrat',
      fontSize: '0.75rem',
      opacity: 0.5,
      [theme.breakpoints.up(1300)]: {
        fontSize: '1.125rem',
        fontWeight: 300,
      },
    },
    '& .amount': {
      fontFamily: 'Montserrat',
      fontSize: '1.25rem',
      fontWeight: 500,
      [theme.breakpoints.up(1300)]: {
        fontSize: '2rem',
      },
    },
  },
  data: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
