import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  button: {
    backgroundImage: 'linear-gradient(85deg, #0a5669 6%, #0a5669 94%)',
    height: '3.18rem',
    padding: '1rem',
    color: '#FFF',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    borderRadius: '10px',
    boxShadow: '4px 4px 8px 2px rgba(0, 0, 0, 0.2)',
    textTransform: 'inherit',
    width: '75%',
    '& .imageContainer': {
      '& .addIcon': {
        color: '#06aac3',
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up(1280)]: {
      width: '100%',
    },
  },
}));

export default useStyles;
