import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import MyProducts from '@components/MyAccounts/MyProducts/MyProducts';
import {
  createManualAccount,
  getFinerioAccounts,
  getFinerioTransactions,
} from '@apollo';
import { useFinerioAccountsTotals } from '@hooks';
import { SpinnerWithText } from '@components/Reusables';
import { delay } from 'lodash';
import MonthBalance from '../components/monthBalance';
import MyBalanceTable from '../Habits/MyBalance/MyBalanceTable';
import { finerioTransactionsByCategory } from '@utils';
import CoolebraButton from '@components/Coolebra/coolebraButton';
import { useApolloClient } from '@apollo/client';
import AddProductButton from '../components/addProductButton';
import { NoTransactions } from '../Habits/NoTransactions/NoTransactions';
import AccountData from '@components/MyAccounts/AccountData/AccountData';
import ConnectedBank from '../ConnectedBank/ConnectedBank';
import CardWarning from '../CardWarning/CardWarning';

type Props = {
  fullScreen?: boolean;
  setFullScreen?: Dispatch<SetStateAction<boolean>>;
  isTransactions: boolean;
  setTransactions: (v: boolean) => void;
  isUpdatingAccount: boolean;
};

export const MyAccountsPage = ({
  fullScreen,
  setFullScreen,
  setTransactions,
  isTransactions,
  isUpdatingAccount,
}: Props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1240px)');
  const { finerioAccounts, refetchFinerioAccounts } = getFinerioAccounts();
  const { finerioTransactions } = getFinerioTransactions();
  const [isAddProduct, setAddProduct] = useState<boolean>(false);
  const [stepProduct, setStepProduct] = useState(0);
  const [selectBank, setSelectBank] = useState(0);
  const [isProduct, setProduct] = useState<boolean>(false);
  const [bankNotSelected, setBankNotSelected] = useState<Array<number>>([]);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const client = useApolloClient();

  const finerioGlobals = useFinerioAccountsTotals({
    finerioAccounts,
    bankNotSelected,
  });

  const [isFirstWarning, setFirstWarning] = useState(true);
  const [isSecondWarning, setSecondWarning] = useState(true);
  const [isThreeWarning, setThreeWarning] = useState(true);

  const closeFirstWarning = useCallback(() => {
    setFirstWarning(false);
  }, [isFirstWarning]);

  const closeSecondWarning = useCallback(() => {
    setSecondWarning(false);
  }, [isSecondWarning]);

  const closeThreeWarning = useCallback(() => {
    setThreeWarning(false);
  }, [isThreeWarning]);

  useEffect(() => {
    finerioAccounts.length !== 0 ? setProduct(true) : setProduct(false);
    setTransactions && finerioTransactions.length !== 0
      ? setTransactions(true)
      : setTransactions(false);
  }, [finerioAccounts, finerioTransactions, setTransactions]);

  const closeAddAccount = () => {
    setStepProduct(0);
    setAddProduct(false);
  };

  const refetchAndDelayAccounts = (time) => {
    delay(
      function (content) {
        refetchFinerioAccounts();
        setShowSpinner(false);
      },
      time,
      'ready to refetch'
    );
  };

  const showMessage = () => {
    setShowSpinner(true);
    refetchAndDelayAccounts(60000);
  };

  const finerioData = finerioTransactionsByCategory(finerioTransactions);

  const handleNewAccount = (bankId: number) => {
    createManualAccount(client, { bankId: bankId });
    refetchAndDelayAccounts(1000);
    setAddProduct(false);
  };

  const accountsConnectedApiba = finerioAccounts.filter(
    (acc) => acc.accountType !== ''
  );

  const accountsConnectedManual = finerioAccounts.filter(
    (acc) => acc.accountType == ''
  );

  return (
    <>
      <>
        {!isTransactions && (
          <NoTransactions setAddProduct={() => setAddProduct(true)} />
        )}
        {isTransactions && (
          <section className={classes.containerProd}>
            {isMobile ? (
              <>
                <>
                  {/*  {accountsConnectedApiba.length > 0 ? ( */}
                  <AccountData dataAccount={finerioGlobals} />
                  {/*   ) : (
                    <div className={classes.widthButton}>
                      {accountsConnectedApiba.length < 1 && (
                        <AddProductButton
                          onAddProductClick={() => {
                            setAddProduct(true);
                          }}
                          text="Sincronizar Banco"
                        />
                      )}
                    </div>
                  )} */}
                  {/* {isFirstWarning && (
                    <CardWarning
                      text={
                        <>
                          Aquí encontrarás el balance entre tus ingresos y
                          <br />
                          gastos de este mes.
                        </>
                      }
                      onClick={() => {
                        closeFirstWarning();
                      }}
                    />
                  )} */}
                  {showSpinner && (
                    <SpinnerWithText
                      text={'Tus productos estarán disponibles prontamente'}
                    />
                  )}
                  {isUpdatingAccount && (
                    <SpinnerWithText
                      text={'Tus productos se están actualizando'}
                    />
                  )}
                  <div className={classes.misProductos}>
                    {isTransactions && (
                      <>
                        {!(accountsConnectedApiba.length > 0) && (
                          <MonthBalance
                            monthAmount={finerioGlobals.totalBalance}
                          />
                        )}
                        <MyBalanceTable
                          className={
                            accountsConnectedApiba.length > 0
                              ? classes.noTop
                              : ''
                          }
                          data={finerioData}
                        />
                      </>
                    )}
                    {/* {isSecondWarning && (
                      <CardWarning
                        text={
                          <>
                            Y aquí puedes prender y apagar los bancos
                            <br />
                            conectados para ver su saldo y descuentos
                          </>
                        }
                        onClick={() => {
                          closeSecondWarning();
                        }}
                        secondVersion
                      />
                    )} */}
                    {/* <MyProducts
                      isProduct={isProduct}
                      bankNotSelected={bankNotSelected}
                      setBankNotSelected={setBankNotSelected}
                      onAddProductClick={() => {
                        setAddProduct(true);
                      }}
                    /> */}
                    <CoolebraButton
                      disabled={accountsConnectedManual.length <= 1}
                      banks
                    />
                  </div>
                </>
              </>
            ) : (
              <>
                <div className={classes.desktopFirst}>
                  {isTransactions && (
                    <>
                      {accountsConnectedApiba.length > 0 ? (
                        <AccountData dataAccount={finerioGlobals} />
                      ) : (
                        <>
                          <MonthBalance
                            monthAmount={finerioGlobals.totalBalance}
                          />
                        </>
                      )}
                    </>
                  )}
                  {/* {isFirstWarning && (
                    <CardWarning
                      text={
                        <>
                          Aquí encontrarás el balance entre tus ingresos y
                          <br />
                          gastos de este mes.
                        </>
                      }
                      onClick={() => {
                        closeFirstWarning();
                      }}
                    />
                  )} */}
                  {showSpinner && (
                    <SpinnerWithText
                      text={'Tus productos estarán disponibles prontamente'}
                    />
                  )}
                  {isUpdatingAccount && (
                    <SpinnerWithText
                      text={'Tus productos se están actualizando'}
                    />
                  )}
                  {isTransactions && (
                    <>
                      <div className={classes.tableDesktop}>
                        <MyBalanceTable data={finerioData} />
                      </div>
                      {/*  {accountsConnectedApiba.length < 1 && (
                        <AddProductButton
                          onAddProductClick={() => {
                            setAddProduct(true);
                          }}
                          text="Sincronizar Banco"
                        />
                      )} */}
                    </>
                  )}
                </div>

                <div className={classes.desktopSecond}>
                  <div className={classes.myProductsDesktop}>
                    <div className={classes.containerTop}>
                      <div className="top">
                        <h4>Tus bancos</h4>
                      </div>
                      {/* <AddProductButton
                        onAddProductClick={() => {
                          setAddProduct(true);
                        }}
                      /> */}
                    </div>
                    <MyProducts
                      isProduct={isProduct}
                      bankNotSelected={bankNotSelected}
                      setBankNotSelected={setBankNotSelected}
                      onAddProductClick={() => {
                        setAddProduct(true);
                      }}
                    />
                    <CoolebraButton
                      disabled={
                        accountsConnectedManual.length <= 1 &&
                        accountsConnectedApiba.length < 1
                      }
                      banks
                    />
                  </div>
                </div>
              </>
            )}
          </section>
        )}
      </>
      {isAddProduct && (
        <ConnectedBank
          closeMenu={() => setAddProduct(false)}
          showMessage={showMessage}
          setFullScreen={setFullScreen}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  noProduct: {
    padding: '1rem 1rem 2rem 1rem',
    marginTop: '-4rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    '& .title': {
      display: 'flex',
      justifyContent: 'center',
      gap: '1.5rem',
      alignItems: 'center',
    },
    [theme.breakpoints.up(1240)]: {
      padding: '4rem 1rem 0rem 1rem',
    },
  },
  noProductSecond: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    '& .subtitle': {
      fontFamily: 'Montserrat',
      fontSize: '0.8rem',
      fontWeight: 'normal',
      color: '#FFFFFF',
      opacity: '0.6',
    },
    '& .icon': {
      transform: 'rotate(-90deg)',
      color: '#FFFFFF',
    },
  },
  noProductSecondDesktop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    '& .subtitle': {
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 'normal',
      color: '#393E44',
      opacity: '0.6',
    },
    '& .icon': {
      height: '2rem',
      width: '2rem',
      transform: 'scaleX(-1) rotate(90deg)',
      color: '#0A5669',
    },
  },
  title: {
    '& img': {
      width: '7rem',
    },
  },
  borderDesktop: {
    [theme.breakpoints.up(1240)]: {
      '& >div': {
        borderRadius: '0',
      },
    },
  },
  noProducts: {
    left: '0px',
    marginTop: '-3rem',
    width: '59%',
    position: 'absolute',
    zIndex: 10,
    display: 'flex',
    backdropFilter: 'blur(3px)',
    color: 'white',
    fontSize: 36,
    alignItems: 'center',
    fontWeight: 'bold',
    justifyContent: 'center',
    overflowY: 'hidden',
    paddingBottom: '8rem',
    [theme.breakpoints.down(1240)]: {
      marginTop: '-2rem',
      width: '100%',
      height: '18rem',
    },
    [theme.breakpoints.down(850)]: {
      height: '20rem',
    },
    [theme.breakpoints.down(514.5)]: {
      height: '21.5rem',
    },
  },
  noProductsText: {
    marginTop: '8rem',
    [theme.breakpoints.down(1240)]: {
      marginTop: '6rem',
    },
  },
  astronaut: {
    width: '7.2rem',
  },
  misProductos: {
    background: '#eaeaea',
    color: theme.palette.text.primary,
    padding: '1rem',
    /*     borderRadius: '1rem 1rem 0 0',
     */ display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
    paddingBottom: '8rem',
    [theme.breakpoints.down(1240)]: {
      marginTop: '10rem',
    },
  },
  widthButton: {
    width: 300,
    margin: '0 auto',
  },
  containerProd: {
    [theme.breakpoints.up(1240)]: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'center',
      padding: '4rem',
      gap: '5rem',
    },
  },
  childrenCorrection: {
    marginTop: '-6rem',
  },
  desktopFirst: {
    width: '26.125rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  tableDesktop: {
    background: '#F4F3F3',
    borderRadius: 15,
    padding: '0rem 1rem',
    height: '23.25rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up(1240)]: {
      background: 'none',
    },
  },
  myProductsDesktop: {
    width: '28.125rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    background: '#F4F3F3',
    borderRadius: 15,
    boxShadow: '5.3px 5.3px 10.5px 2.6px rgb(0 0 0 / 20%)',
    padding: '2rem',
    height: '100%',
    minHeight: '29.813rem',
    justifyContent: 'start',
    alignItems: 'center',
  },
  desktopSecond: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  rectangle: {
    background: '#00323c',
    padding: '1rem 1.7rem',
    color: 'white',
    position: 'relative',
    width: '14rem',
    textAlign: 'center',
    fontSize: '0.875rem',
    borderRadius: '0.25rem',
    '& .triangle': {
      width: 0,
      top: 20,
      left: -15,
      height: 0,
      borderTop: '10px solid transparent',
      borderRight: '15px solid #00323c',
      borderBottom: '10px solid transparent',
      transform: 'rotate(0deg)',
      position: 'absolute',
    },
  },
  blockBottom: {
    height: '15rem',
    borderRadius: '20px 20px 0 0',
    padding: '2rem 3rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    maxWidth: 470,
    margin: '0 auto',
  },
  item: {
    color: 'white',
    display: 'flex',
    fontWeight: 300,
    fontSize: '0.75rem',
    gap: '2rem',
    alignItems: 'center',
    '& img': {
      width: '1.3rem',
    },
    '& strong': {
      fontWeight: 500,
    },
  },
  button: {
    display: 'flex',
    height: '10rem',
    marginTop: '1rem',
    justifyContent: 'center',
    gap: '0.5rem',
    '& button': {
      fontSize: '0.875rem',
      width: '10.5rem',
      borderRadius: 20,
    },
  },
  ButtonGreen: {
    color: '#54e5ff',
    borderColor: '#54e5ff',
  },
  background: {
    background: '#00323c',
    borderRadius: '20px 20px 0 0',
  },
  containerTop: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    '& h4': {
      textTransform: 'uppercase',
      color: '#282828',
    },
  },
  top: {
    '& >div': {
      width: '80%',
      margin: '0 auto',
    },
    width: '100%',
    margin: '0 auto',
  },
  noTop: {
    marginTop: '-10rem',
  },
}));
