import React from 'react';
import useStyles from './addMovementButton.style';
import { Button } from '@material-ui/core';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';

interface props {
  text?: string;
}

const AddMovementButton = ({ text }: props) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonContainer}>
      <Button
        className={classes.button}
        onClick={() => navigate(Route.addMovement)}
      >
        <div className="imageContainer">
          <AddRoundedIcon className="addIcon" />
        </div>
        <span>{text || 'Agregar movimiento'}</span>
      </Button>
    </div>
  );
};

export default AddMovementButton;
