import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerAll: {
    padding: '1rem',
    borderRadius: 13,
    margin: '0rem auto',
    width: '90%',
    minWidth: '20rem',
    [theme.breakpoints.up(1300)]: {
      background: '#ffffff',
      maxWidth: '24.625rem',
    },
  },
  containerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .left': {
      display: 'flex',
      gap: '0.5rem',
      flexDirection: 'column',
      '& .title': {
        color: '#959595',
        fontSize: '0.75rem',
      },
      '& .amount': {
        color: '#646464',
        fontFamily: 'Montserrat',
        fontSize: '1rem',
        fontWeight: 'bold',
      },
      '& .amountMonth': {
        color: '#646464',
        fontSize: '0.75rem',
        '& span': {
          color: '#459a27',
        },
      },
      '& .rule': {
        color: '#b3261e',
        fontSize: '0.75rem',
      },
    },
    '& .right': {
      display: 'flex',
      gap: '0.5rem',
      flexDirection: 'column',
      alignItems: 'end',
      justifyContent: 'space-around',
      '& .title': {
        color: '#959595',
        fontSize: '0.625rem',
      },
      '& img': {
        width: '2.8rem',
        padding: '0.3rem',
      },
    },
  },
  line: {
    width: '95%',
    minWidth: '20rem',
    borderBottom: '1px solid #d1d1d1',
    height: '1rem',
    padding: '0.5rem 0',
    opacity: 0.6,
  },
  containerBottom: {
    color: '#646464',
    fontSize: '0.875rem',
    textAlign: 'center',
    padding: '0.5rem 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    '& .spanContainer': {
      padding: '0 1rem',
    },
  },
  bottomText: {
    fontSize: '0.75rem',
    fontWeight: 300,
    textAlign: 'center',
    display: 'block',
    padding: '0 1.5rem',
  },
}));

export default useStyles;
