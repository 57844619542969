import React from 'react';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';

import { ButtonOutlined, ButtonPrimary } from '@components/Reusables';
import { ASTRONAUT, CALENDAR_ICON_GREEN, LINK_ICON_GREEN } from 'images/GCP';
import { SEARCH_ICON } from 'images/GoogleCloud';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';

interface props {
  setAddProduct: () => void;
  habits?: boolean;
}

export const NoTransactions = ({ setAddProduct, habits = false }: props) => {
  const isMobile = useMediaQuery('(max-width: 1240px)');
  const classes = useStyles({ habits, isMobile });

  return (
    <section className={classes.container}>
      <div className={classes.noProduct}>
        <span className="title">
          <img className={classes.astronaut} src={ASTRONAUT} />
          <div className={classes.rectangle}>
            <div className="triangle" />
            Hmmm no hay nada por aquí... Comienza por{' '}
            <strong>conectar tus bancos</strong> o{' '}
            <strong>registrar movimientos</strong> y podrás:
          </div>
        </span>
      </div>
      <div className={classes.background}>
        <div className={classes.blockBottom}>
          <div className={classes.item}>
            <img src={LINK_ICON_GREEN} />
            <span>
              {' '}
              <strong>Sincronizar tus productos financieros, </strong>
              para ver todas tus cuentas y tarjetas en un solo lugar
            </span>
          </div>
          <div className={classes.item}>
            <img src={SEARCH_ICON} />
            <span>
              <strong>Conocer y monitorear tus gastos, </strong>y ponerte metas
              para aumentar tu ahorro
            </span>
          </div>
          <div className={classes.item}>
            <img src={CALENDAR_ICON_GREEN} />
            <span>
              <strong>Crear recordatorios, </strong>
              para todas tus actividades financieras
            </span>
          </div>
        </div>
        <div className={classes.button}>
          {/* <ButtonOutlined
            className={classes.ButtonGreen}
            text="Sincronizar banco"
            onClick={setAddProduct}
          /> */}
          <ButtonPrimary
            text="Agregar movimiento"
            onClick={() => navigate(Route.addMovement)}
            noDegraded
          />
        </div>
      </div>
    </section>
  );
};

const useStyles = makeStyles<Theme, { habits: boolean; isMobile: boolean }>(
  (theme) => ({
    container: {
      [theme.breakpoints.down(1240)]: {
        marginTop: ({ habits }) => habits && '1rem',
        paddingTop: ({ habits }) => habits && '5rem',
        background: '#008195',
      },
      [theme.breakpoints.up(1240)]: {
        background: '#001E28',
        padding: '5rem 5rem',
        width: '90%',
        margin: '2rem auto 0',
        borderRadius: 30,
        display: 'flex',
        gap: '6rem',
        justifyContent: 'center',
      },
    },
    noProduct: {
      padding: '1rem 1rem 2rem 1rem',
      marginTop: ({ habits, isMobile }) => (habits && isMobile ? '-4rem' : 0),
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      justifyContent: 'center',
      '& .title': {
        display: 'flex',
        justifyContent: 'center',
        gap: '1.5rem',
        alignItems: 'center',
        [theme.breakpoints.up(1240)]: {
          flexDirection: 'column',
        },
      },
      [theme.breakpoints.up(1240)]: {
        padding: '0rem 1rem 0rem 1rem',
        marginTop: 0,
      },
    },
    title: {
      '& img': {
        width: '7rem',
      },
    },
    astronaut: {
      width: '7.2rem',
      [theme.breakpoints.up(1240)]: {
        width: '12.2rem',
      },
    },
    misProductos: {
      background: '#eaeaea',
      color: theme.palette.text.primary,
      padding: '1rem',
      borderRadius: '1rem 1rem 0 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '2rem',
      paddingBottom: '8rem',
      [theme.breakpoints.down(1240)]: {
        marginTop: '5rem',
      },
    },
    containerProd: {
      [theme.breakpoints.up(1240)]: {
        display: 'flex',
        flexDirection: 'column',
        padding: '4rem',
        gap: '5rem',
      },
    },
    rectangle: {
      background: '#00323c',
      padding: '1rem 1.7rem',
      color: 'white',
      position: 'relative',
      width: '14rem',
      textAlign: 'center',
      fontSize: '0.875rem',
      borderRadius: '0.5rem',
      [theme.breakpoints.up(1240)]: {
        padding: '1rem 2.5rem',
      },
      '& .triangle': {
        width: 0,
        top: 20,
        left: -15,
        height: 0,
        borderTop: '10px solid transparent',
        borderRight: '15px solid #00323c',
        borderBottom: '10px solid transparent',
        transform: 'rotate(0deg)',
        position: 'absolute',
        [theme.breakpoints.up(1240)]: {
          transform: 'rotate(90deg)',
          top: -15,
          left: '6.5rem',
        },
      },
    },
    blockBottom: {
      height: '15rem',
      padding: '2rem 3rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      maxWidth: 470,
      margin: '0 auto',
      [theme.breakpoints.up(1240)]: {
        padding: '2rem 4rem',
      },
    },
    item: {
      color: 'white',
      display: 'flex',
      fontWeight: 300,
      fontSize: '0.75rem',
      gap: '2rem',
      alignItems: 'center',
      '& img': {
        width: '1.3rem',
      },
      '& strong': {
        fontWeight: 500,
      },
    },
    button: {
      display: 'flex',
      height: '10rem',
      marginTop: '1rem',
      justifyContent: 'center',
      gap: '0.5rem',
      [theme.breakpoints.up(1240)]: {
        height: '6rem',
        marginTop: '2rem',
      },
      '& button': {
        fontSize: '0.875rem',
        width: '10.5rem',
        borderRadius: 20,
        [theme.breakpoints.up(1240)]: {
          width: '9.5rem',
        },
      },
    },
    ButtonGreen: {
      color: '#54e5ff',
      borderColor: '#54e5ff',
    },
    background: {
      background: '#00323c',
      borderRadius: '20px 20px 0 0',
      [theme.breakpoints.up(1240)]: {
        borderRadius: 20,
        background: '#00323C',
        maxWidth: '25rem',
      },
    },
  })
);
