import React, { useState } from 'react';
import useStyles from './chartCatergories.style';
import MyExpensesGraphic from '../Habits/MyExpenses/MyExpenses';
import MyExpensesTable from '../Habits/MyExpenses/MyExpensesTable';
import { useMediaQuery } from '@material-ui/core';
import { Select } from '@components/Reusables';

interface propsDataGoal {
  data: any;
  monthOptions: () => {
    name: string;
    value: number;
  }[];
  setSelectedMonth: (v: number) => void;
  selectedMonth: number;
}

const ChartCategories = ({
  data,
  monthOptions,
  setSelectedMonth,
  selectedMonth,
}: propsDataGoal) => {
  const isMobile = useMediaQuery('(max-width: 1280px)');
  const [isGraphic, setGraphic] = useState(false);
  const classes = useStyles({ isGraphic: isGraphic });
  const [isAll, setAll] = useState(false);

  return (
    <div className={classes.graphicSpents}>
      <div className={classes.flex}>
        <div className={classes.topGraphicSpents}>
          <div className={classes.optionsTopGraphicSpents}>
            <div className="graphic" onClick={() => setGraphic(true)}>
              Gráfico{' '}
            </div>
            <div className="table" onClick={() => setGraphic(false)}>
              Tabla
            </div>
          </div>
        </div>
        <Select
          className={classes.color}
          options={monthOptions()}
          onChange={(value) => setSelectedMonth(value.target.value as number)}
          value={selectedMonth}
          disableError={true}
          underscore
        />
      </div>
      <div className={classes.graphic}>
        {isGraphic ? (
          <MyExpensesGraphic data={data} />
        ) : (
          <MyExpensesTable isAll={isAll} data={data} />
        )}
        {!isGraphic &&
          data.categories.length >= 4 &&
          (!isAll && !isGraphic ? (
            <span className={classes.viewAll} onClick={() => setAll(true)}>
              Ver más
            </span>
          ) : (
            <span className={classes.viewAll} onClick={() => setAll(false)}>
              Ver menos
            </span>
          ))}
      </div>{' '}
    </div>
  );
};

export default ChartCategories;
