import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./MyExpenses.style";
import { ResponsivePie } from "@nivo/pie";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { navigate } from "gatsby";
import { Route } from "@interfaces";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface MyExpensesProps {
  data: {
    name: string;
    total: number;
    amount: number;
    percentage: number;
    type: string;
    months: number;
    average: number;
  }[];
}

const MyExpensesGraphic = ({ data }: MyExpensesProps) => {
  const isMobile = useMediaQuery("(max-width: 700px)");
  const arrayColors: any[] = [];

  const dataPie: any[] = [];

  let totalExpense = data?.categories
    .map((item) => item.expense)
    .reduce((a, b) => a + b);

  data?.categories.map((d) => {
    arrayColors.push(d.color);
    dataPie.push({
      id: d.name,
      label: d.name,
      value: ((d.expense * 100) / totalExpense).toFixed(1),
      color: d.color,
    });
  });

  const classes = useStyles();

  const handleOnClick = (info: any) => {
    const categoryAmount = data?.categories.find(
      (item) => item.name === info.id
    )?.expense;
    const categoryTransactions = data?.categories.find(
      (item) => item.name === info.id
    )?.transactions;
    const averageArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    data?.categories
      .find((item) => item.name === info.id)
      ?.transactions.map((trans, index) => {
        const month = (new Date(trans.date)).getMonth();
        if (month === 0) {
          averageArray[month] += trans.amount;
        } else {
          averageArray[month] += trans.amount;
        }
      });

    navigate(Route.habitsDetails, {
      state: {
        name: info.id,
        amount: categoryAmount,
        total: totalExpense,
        percentage: info.value,
        average: averageArray,
        transactions: categoryTransactions,
      },
    });
  };

  return (
    <div className={classes.container}>
      <ResponsivePie
        data={dataPie ?? []}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.65}
        padAngle={2}
        cornerRadius={1}
        activeOuterRadiusOffset={8}
        colors={arrayColors}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        enableArcLinkLabels={isMobile ? false : true}
        arcLinkLabelsSkipAngle={6}
        arcLinkLabelsTextOffset={6}
        arcLinkLabelsTextColor={{ from: "color", modifiers: [] }}
        arcLinkLabelsDiagonalLength={16}
        arcLinkLabelsStraightLength={24}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        enableArcLabels={isMobile ? true : false}
        arcLabelsSkipAngle={10}
        arcLabelsRadiusOffset={0.4}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        legends={[]}
        onClick={(data, event) => {
          handleOnClick(data);
        }}
      />{" "}
    </div>
  );
};

export default MyExpensesGraphic;
