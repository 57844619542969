import React, { useCallback, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useStyles } from './AccountData.style';
import { CREDIT_ICON, CURRENT_BALANCE_ICON, TipIcon } from '@images';
import { useCurrentUser } from '@apollo';
import { FinerioGlobals } from '@interfaces';
import { parseNumber } from '@utils';
import CardWarning from '@components/FinancialPortal/CardWarning/CardWarning';

interface AddProductProps {
  dataAccount: FinerioGlobals;
}

const AccountData = ({ dataAccount }: AddProductProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1280px)');
  const { user } = useCurrentUser();

  const [isFirstWarning, setFirstWarning] = useState(true);

  const closeFirstWarning = useCallback(() => {
    setFirstWarning(false);
  }, [isFirstWarning]);

  return (
    <>
      {/* {isFirstWarning && (
        <CardWarning
          text={
            <>
              Aquí se muestra el dinero disponible y las <br />
              deudas combinadas de tus bancos sincronizados.
            </>
          }
          onClick={() => {
            closeFirstWarning();
          }}
        />
      )} */}
      <div className={classes.container}>
        <div className={'topSection'}>
          <div className="cardVertical">
            <img src={CURRENT_BALANCE_ICON} />
            <div className={classes.amount}>
              $ {parseNumber(dataAccount.totalBalance)}
            </div>
            <p>
              Saldo actual en
              <br /> tus cuentas
            </p>
          </div>
          <div className="cardVertical">
            <img src={CREDIT_ICON} />
            <div>
              <span className={classes.amount}>
                $ {dataAccount.totalCreditBalance.toLocaleString('es')}
              </span>
            </div>
            <p>
              Monto disponible en
              <br /> tarjetas de crédito
            </p>
          </div>
        </div>
        <div className="cardHorizontal">
          {' '}
          <img
            src={
              'https://storage.googleapis.com/rokinapp-images/MyFinances/myAccount/wallet_icon%20(3).svg'
            }
          />
          <div className={classes.dataCard}>
            <h4>En tu bolsillo</h4>
            <p className={classes.amountCard}>
              Si pagas tus tarjetas te quedan{' '}
              <span className={classes.amount}>
                $ {dataAccount.amountAfterPayingCards.toLocaleString('es')}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountData;
