import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useStyles } from './Layout.style';
import { useCurrentUser } from '@apollo';

interface LayoutMyAccountsProps {
  children: React.ReactNode;
  title: string;
  onBackClick: () => void;
  className?: string;
  isDesktop?: boolean;
}

const LayoutMyAccounts = ({
  children,
  title,
  onBackClick,
  className,
  isDesktop,
}: LayoutMyAccountsProps) => {
  const classes = useStyles({ isDesktop: isDesktop });
  const isMobile = useMediaQuery('(max-width: 1280px)');
  const { user } = useCurrentUser();

  return (
    <>
      <div className={`${classes.container} ${className}`}>
        <div className={classes.containerTop}>
          <img
            src={
              'https://rokinapp-images.s3.amazonaws.com/icons/atras_icon.svg'
            }
            onClick={onBackClick}
          />
          <h1>{title}</h1>
        </div>
        <div className={classes.containerChildren}>{children}</div>
      </div>
    </>
  );
};

export default LayoutMyAccounts;
