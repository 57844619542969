import React, { useCallback } from 'react';
import { useStyles } from './MyProducts.style';
import { disableEnableFinerioCredential } from '@apollo';
import { getFinerioAccounts } from '@apollo';
import { finerioListBank } from '@data';
import { Switch, useMediaQuery } from '@material-ui/core';
import { Link } from '@reach/router';
import { Route } from '@interfaces';
import SubdirectoryArrowLeftRoundedIcon from '@mui/icons-material/SubdirectoryArrowLeftRounded';
import AddProductButton from '@components/FinancialPortal/components/addProductButton';
import { Close } from '@material-ui/icons';
import { useApolloClient } from '@apollo/client';
import { getFinerioCredentials } from 'apollo/finerio/finerioCredentials.query';
import { CardNotAdded } from '@components/Finance/CardNotAdded/CardNotAdded';

interface MyProductsProps {
  isProduct: boolean;
  onAddProductClick: () => void;
  bankNotSelected: Array<any>;
  setBankNotSelected: (value: Array<any>) => void;
}

const MyProducts = ({
  isProduct,
  onAddProductClick,
  bankNotSelected,
  setBankNotSelected,
}: MyProductsProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1240px)');
  const client = useApolloClient();
  const { finerioAccounts, refetchFinerioAccounts } = getFinerioAccounts();
  const { finerioCredentials } = getFinerioCredentials();

  const accountsConnectedApiba = finerioAccounts.filter(
    (acc) => acc.accountType !== ''
  );
  const connectedBanksId = [
    ...new Set(accountsConnectedApiba.map((account) => account.bankId)),
  ];
  const accountsConnectedManual = finerioAccounts.filter(
    (acc) => acc.accountType == ''
  );

  const manualBanksId = useCallback(() => {
    const manualBanksIdPreload: number[] = [
      ...new Set(accountsConnectedManual.map((account) => account.bankId)),
    ].sort((a, b) => a - b);
    return manualBanksIdPreload;
  }, [finerioAccounts]);

  const deleteManualBank = (bankId: number) => {
    const credentialId = finerioCredentials.find(
      (cred) => cred.bankId == bankId
    )?.id;
    disableEnableFinerioCredential(client, {
      credentialId: Number(credentialId),
    });
    refetchFinerioAccounts();
  };

  return (
    <>
      <div className={classes.container}>
        {!isProduct && isMobile && (
          <div className={classes.noProducts}>
            <span className="subtitle">
              Agrega productos para ordenar tu plata
            </span>
            <SubdirectoryArrowLeftRoundedIcon className="icon" />
          </div>
        )}
        {/* {isMobile && (
          <div className={classes.containerTop}>
            <div className="top">
              <h4>Tus bancos</h4>
            </div>
            <AddProductButton onAddProductClick={onAddProductClick} />
          </div>
        )} */}
        {isProduct && (
          <div className={classes.products}>
            {accountsConnectedApiba.length > 0 ? (
              <>
                <div className="autoAccounts">Cuentas sincronizadas</div>
                {connectedBanksId.map((bank) => {
                  return (
                    <div
                      className={classes.product}
                      key={bank}
                      onClick={() => {}}
                    >
                      <Link
                        className={classes.link}
                        to={Route.accountDetails}
                        state={{
                          bankId: bank,
                          finerioAccounts: finerioAccounts,
                        }}
                        key={bank}
                      >
                        <div className={classes.productImg}>
                          <img
                            src={
                              finerioListBank.find(
                                (item) => item.finerioBankId === bank
                              )?.icon
                            }
                          />
                        </div>
                        <div className={classes.productInfo}>
                          <div>
                            <h4>
                              {
                                finerioListBank.find(
                                  (item) => item.finerioBankId === bank
                                )?.name
                              }
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <div className={classes.check}>
                        <Switch
                          color="primary"
                          name="checkedB"
                          className={classes.checkToggle}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          checked={!bankNotSelected.includes(bank)}
                          onChange={() => {
                            if (bankNotSelected.includes(bank)) {
                              setBankNotSelected(
                                bankNotSelected.filter((item) => item !== bank)
                              );
                            } else {
                              setBankNotSelected([...bankNotSelected, bank]);
                            }
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {/*                 <div className="autoAccounts">Cuentas sincronizadas</div>
                 */}{' '}
                <CardNotAdded text="Aún no has sincronizado ninguna cuenta" />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MyProducts;
