import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import useStyles from './Habits.style';
import MovementItem from './MovementItem/MovementItem';
import { META_ICON } from 'images/AWS/habits';
import {
  createManualAccount,
  getFinerioTransactions,
  updateUser,
} from '@apollo';
import { ProgressBarLine } from 'react-progressbar-line';
import { finerioTransactionsByCategory, parseNumber } from '@utils';
import { getFinerioAccounts } from '@apollo';
import { CloseRounded } from '@material-ui/icons';
import { ButtonPrimary, NumericInput } from '@components/Reusables';
import ChartCategories from '../components/chartCategories';
import AddMovementButton from '../components/addMovementButton';
import { Select } from '@components/Reusables';
import { currentUserPreferences } from '@apollo';
import { useForm } from '@hooks';
import { useApolloClient } from '@apollo/client';
import { NoTransactions } from './NoTransactions/NoTransactions';
import { delay } from 'lodash';
import AddProduct from './AddProduct/AddProduct';
import { PEN_ICON } from 'images/GoogleCloud';

interface HabitsProps {
  title?: string;
  togglePage: () => void;
}

const MyHabits = ({ title, togglePage }: HabitsProps) => {
  const isMobile = useMediaQuery('(max-width: 1280px)');
  const [isGraphic, setGraphic] = useState(true);
  const client = useApolloClient();
  const [form, , , handleChange] = useForm({});
  const preferences = currentUserPreferences();
  const [isTransactions, setTransactions] = useState<boolean>(false);
  const classes = useStyles({ noTransaction: isTransactions });
  const [stepProduct, setStepProduct] = useState(0);
  const { finerioAccounts, refetchFinerioAccounts } = getFinerioAccounts();

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);
  const [isAddProduct, setAddProduct] = useState(false);
  const { finerioTransactions } = getFinerioTransactions();
  const finerioData = finerioTransactionsByCategory(finerioTransactions);
  finerioData.reverse();

  //Closest date
  const temp = finerioData.map((d) => {
    return Math.abs(new Date() - new Date(d.date).getTime());
  });
  const idx = temp.indexOf(Math.min(...temp));

  const [selectedMonth, setSelectedMonth] = useState(idx);
  const closestDate = finerioData[selectedMonth];
  const previousMonth = finerioData[selectedMonth - 1];

  useEffect(() => {
    setTransactions(finerioTransactions.length !== 0);
  }, [finerioTransactions]);

  useEffect(() => {
    setSelectedMonth(idx);
  }, [idx]);

  useEffect(() => {
    if (isMobile) {
      setGraphic(false);
    }
  }, [isMobile]);

  const infoButtonHandler = () => {
    //OPEN POPUP
    setOpenInfoPopUp(!openInfoPopUp);
  };

  const monthOptions = useCallback(() => {
    const toReturn: { name: string; value: number }[] = [];
    finerioData.map((item, index) => {
      const newDate = new Date(item.date);
      const year = newDate.toLocaleString('es-ES', { year: 'numeric' });
      const monthName = newDate.toLocaleString('es-ES', { month: 'short' });
      toReturn.push({ name: `${monthName}-${year}`, value: index });
    });
    return toReturn;
  }, [finerioTransactions]);

  const getBudgetGoal = (categoryName: string) => {
    const budgetGoal = preferences.budgetGoals?.find(
      (item) => Object.keys(item)[0] === categoryName
    );
    return budgetGoal ? budgetGoal[categoryName] : 0;
  };
  const lastGlobalGoal = getBudgetGoal('GlobalGoal');
  const [globalGoal, setGlobalGoal] = useState(lastGlobalGoal);

  const handlePopUpOnClick = useCallback(
    (goal) => {
      const newBudgetGoal = { GlobalGoal: goal };
      if (preferences === undefined || preferences.budgetGoals === undefined) {
        preferences.budgetGoals = [];
      }
      const index = preferences.budgetGoals.findIndex(
        (item) => Object.keys(item)[0] === 'GlobalGoal'
      );
      // if already exists, update
      if (index !== -1) {
        const newBudgetGoals = preferences?.budgetGoals.map((obj) => {
          if (Object.keys(obj)[0] === 'GlobalGoal') {
            return newBudgetGoal;
          }
          return obj;
        });
        form.preferences = {
          onBoarding: preferences?.onBoarding,
          budgetGoals: newBudgetGoals,
        };
        console.log(form.preferences);
      } else {
        // if not, add it to the array
        const newBudgetGoals = [...preferences?.budgetGoals, newBudgetGoal];
        form.preferences = {
          onBoarding: preferences?.onBoarding,
          budgetGoals: newBudgetGoals,
        };
      }
      setOpenInfoPopUp(false);
      updateUser(form, false, client);
    },
    [form, preferences, client]
  );

  const refetchAndDelayAccounts = (time) => {
    delay(
      function (content) {
        refetchFinerioAccounts();
        setShowSpinner(false);
      },
      time,
      'ready to refetch'
    );
  };

  const showMessage = () => {
    setShowSpinner(true);
    refetchAndDelayAccounts(60000);
  };

  const handleNewAccount = (bankId: number) => {
    createManualAccount(client, { bankId: bankId });
    refetchAndDelayAccounts(1000);
    setAddProduct(false);
  };

  const closeAddAccount = () => {
    setStepProduct(0);
    setAddProduct(false);
  };
  /*
   <Select
                  options={monthOptions()}
                  onChange={(value) => setSelectedMonth(value.target.value)}
                  value={selectedMonth}
                  disableError={true}
                  underscore
                />*/
  const addMovementsDiv = () => {
    return (
      <>
        {isTransactions ? (
          <div className={classes.addMovements}>
            {isMobile && <AddMovementButton />}
            <section className={classes.containerTotal}>
              <img src={META_ICON} />
              <div>
                <span className="title">
                  <span>
                    Gasto total: ${parseNumber(closestDate?.expense ?? 0)}
                  </span>
                  <span>
                    <img src={PEN_ICON} onClick={() => infoButtonHandler()} />
                  </span>
                </span>
                <ProgressBarLine
                  value={closestDate?.expense}
                  min={0}
                  max={previousMonth?.expense}
                  text=" "
                  styles={{
                    path: {
                      stroke: '#008296',
                    },
                  }}
                />
                <span className="meta">
                  <span>Meta de gasto: </span>
                  <span>
                    $
                    {globalGoal === 0 || null
                      ? parseNumber(lastGlobalGoal)
                      : parseNumber(globalGoal)}
                  </span>
                </span>
              </div>
            </section>{' '}
            {!isMobile && <AddMovementButton />}
          </div>
        ) : (
          <>
            <NoTransactions setAddProduct={() => setAddProduct(true)} habits />
          </>
        )}
        <div
          className={
            openInfoPopUp ? classes.popUpContainer : classes.hiddenDisplay
          }
        >
          <div className={classes.outerBorder}>
            <div className={classes.popUp}>
              <div className={classes.itemRow}>
                <h3 className={classes.rokipediaPopUpTitle}>Meta:</h3>
                <button onClick={infoButtonHandler}>
                  <CloseRounded htmlColor="#393E44" />
                </button>
              </div>
              <div className={classes.popUpVector}></div>
              <span className={classes.popUpText}>
                Crea o modifica tu meta de gasto para que puedas ver tu
                progreso.
              </span>
              <div className={classes.goalInput}>
                <NumericInput
                  className="input"
                  name="globalGoal"
                  value={globalGoal}
                  onChange={(e) => {
                    setGlobalGoal(e.target.value);
                  }}
                />
              </div>
              <div className={classes.buttonGotItContainer}>
                <ButtonPrimary
                  className={classes.buttonGotIt}
                  onClick={() => {
                    handlePopUpOnClick(globalGoal);
                  }}
                  text="Entendido"
                  secondaryVersion
                  noDegraded
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return !isAddProduct ? (
    <>
      {isMobile ? (
        <>
          <div className={classes.containerAll}>
            {addMovementsDiv()}
            {isTransactions && (
              <ChartCategories
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
                monthOptions={monthOptions}
                data={closestDate}
              />
            )}
          </div>
          {isTransactions && (
            <section className={classes.movementsContainer}>
              <div className={classes.transactionsTable}>
                <h2 className={classes.titleSpentSection}>Movimientos</h2>
                <AddMovementButton text="Agregar" />
              </div>
              <MovementItem data={finerioTransactions} />
            </section>
          )}
        </>
      ) : isTransactions ? (
        <>
          <section className={classes.habitsWholeContainerDesktop}>
            <div className={classes.expensesBoxDesktop}>
              {addMovementsDiv()}
            </div>
            <ChartCategories
              selectedMonth={selectedMonth}
              monthOptions={monthOptions}
              setSelectedMonth={setSelectedMonth}
              data={closestDate}
            />
          </section>
          <section className={classes.movementsContainer}>
            <div className={classes.transactionsTable}>
              <h2 className={classes.titleSpentSection}>Movimientos</h2>
              <AddMovementButton />
            </div>
            <div className={isMobile ? '' : classes.table}>
              <MovementItem data={finerioTransactions} />
            </div>
          </section>
        </>
      ) : (
        <>
          <NoTransactions setAddProduct={() => setAddProduct(true)} habits />
        </>
      )}
    </>
  ) : (
    <AddProduct setAddProduct={setAddProduct} setShowSpinner={setShowSpinner} />
  );
};

export default MyHabits;
