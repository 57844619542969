import { parseNumber } from '@utils';
import React from 'react';
import useStyles from './monthBalance.style';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';

interface propsDataGoal {
  monthAmount: number;
}

const MonthBalance = ({ monthAmount }: propsDataGoal) => {
  const classes = useStyles();

  return (
    <section className={classes.monthBalance}>
      <div className={classes.grid}>
        <div className={classes.title}>
          <span>Saldo del mes</span>
          {/* <InfoOutlinedIcon className="infoIcon"/> */}
        </div>
        <span className="amount">
          $ {isNaN(monthAmount) ? '0' : parseNumber(monthAmount)}
        </span>
      </div>
      <AddCircleRoundedIcon
        className="addIcon"
        onClick={() => navigate(Route.addMovement)}
      />
    </section>
  );
};

export default MonthBalance;
