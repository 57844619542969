import { CardNotAdded } from '@components/Finance/CardNotAdded/CardNotAdded';
import {
  CAR_BACKGROUND_GREEN_ICON,
  CERDO_BACKGROUND_GREEN_ICON,
  MONEY_BACKGROUND_GREEN_ICON,
} from 'images/GCP';
import React from 'react';
import CardIndividualSavingRules from '../CardIndividualSavingRules/CardIndividualSavingRules';
import CardSavingRules from '../CardSavingRules/CardSavingRules';
import useStyles from './SavingRules.styles';

interface props {
  noRules?: boolean;
  savingRules?: boolean;
  cardsRules?: boolean;
  setEdit?: () => void;
  className?: string;
  noinvest?: boolean;
}

const SavingRules = ({
  setEdit,
  noRules,
  savingRules,
  cardsRules,
  className,
  noinvest,
}: props) => {
  const classes = useStyles();
  return (
    <section className={`${classes.containerAll} ${className}`}>
      <div className={classes.container}>
        <span className="titleCon">REGLAS DE AHORRO</span>
        {noRules && (
          <CardNotAdded
            className={classes.heightCardNotAdded}
            text="Aún no has creado reglas para ahorrar"
            columnVersion
          />
        )}
        {savingRules && (
          <CardSavingRules
            noinvest={noinvest}
            name="Viaje Amsterdam"
            amount={988989.4}
            disabled
          />
        )}
        {cardsRules && (
          <div className={classes.containerRules}>
            {' '}
            <CardIndividualSavingRules
              name="Viaje Amsterdam"
              amount={988989.4}
              amountThisMouth={146000}
              icon={CAR_BACKGROUND_GREEN_ICON}
              type="Por gasto"
              onClick={setEdit}
            />
            <div className={classes.line} />
            <CardIndividualSavingRules
              name="APV"
              amount={988989.4}
              amountThisMouth={146000}
              icon={CERDO_BACKGROUND_GREEN_ICON}
              type="Recurrente"
            />
            <div className={classes.line} />
            <CardIndividualSavingRules
              name="Fondo de emergencia"
              amount={988989.4}
              amountThisMouth={146000}
              icon={MONEY_BACKGROUND_GREEN_ICON}
              type="Recurrente"
            />
            <div className={classes.line} />
            <CardIndividualSavingRules
              name="Fondo de emergencia"
              amount={988989.4}
              amountThisMouth={146000}
              icon={MONEY_BACKGROUND_GREEN_ICON}
              type="Recurrente"
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default SavingRules;
