import React, { useEffect, useState } from 'react';
import { useStyles } from './AddDataAccount.style';
import { DUAL_BALL, ESCUDO_SECURITY_ICON, SECURITY_ICON } from 'images/AWS';
import { EYE_ICON_SECUNDARY, TARGET_ICON } from 'images/GCP';
import { createFinerioCredential } from '@apollo';
import { PasswordInput, RutInput } from '@components/Inputs';
import {
  ButtonPrimary,
  ButtonText,
  ButtonOutlined,
} from '@components/Reusables';
import { FinerioBankInterface } from '@interfaces';
import axios from 'axios';
import { ALERT_ICON, CONNECTION_ICON, SUCCESSFUL } from 'images/AWS/myAccount';
import { useMediaQuery } from '@material-ui/core';
import { AddAccountModal } from '../AddAccountModal/AddAccountModal';
import { Dialog } from '@material-ui/core';
import { useApolloClient } from '@apollo/client';
import { ArrowBackIos } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';

interface AddDataAccountProps {
  selectBank: FinerioBankInterface | undefined;
  closeAddAccount: () => void;
  showMessage: () => void;
}

const AddDataAccount = ({
  selectBank,
  closeAddAccount,
  showMessage,
}: AddDataAccountProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1280px)');
  const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
  const [error, setError] = useState<string>(''); // "wrongCredentials" || "connectionError";
  const [successful, setSuccessful] = useState<boolean>(false);
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
  const client = useApolloClient();

  const [dataBank, setDataBank] = useState({
    username: '',
    password: '',
    bankId: selectBank?.finerioBankId,
  });

  const handleClick = () => {
    setCreatingAccount(true);
    createFinerioCredential(client, {
      username: dataBank.username,
      password: dataBank.password,
      bankId: dataBank.bankId ?? 1,
    })
      .then((response) => {
        setSuccessful(true);
        setCreatingAccount(false);
        setError('');
        showMessage();
      })
      .catch((error) => {
        if (
          error.response?.statusCode == 401 ||
          error.response?.status == 401
        ) {
          setError('wrongCredentials');
        } else {
          setError('connectionError');
        }
        setCreatingAccount(false);
        setSuccessful(false);
      });
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleClose = () => setOpenModal(false);
  const handleOpen = () => setOpenModal(true);

  useEffect(() => { 
    if (dataBank.username && dataBank.password) {
      if(dataBank.username.length > 3 && dataBank.password.length > 3) {
        setButtonDisabled(false)
      } else {
        setButtonDisabled(true)
      }
    } else {
      setButtonDisabled(true)
    }
  }, [dataBank])

  return creatingAccount ? (
    <div className={classes.loading}>
      <img src={DUAL_BALL} />
      <div className={classes.textLoading}>
        <span className="title">Iniciando sesión</span>
        <span className="subtitle">
          Estará listo en menos de lo que tomas un café
        </span>
      </div>
    </div>
  ) : successful ? (
    <>
      <div className={classes.loading}>
        <img src={SUCCESSFUL} />
        <div className={`${classes.textLoading} ${classes.textLoading2}`}>
          <span className="title2">¡ Felicidades !</span>
          <span className="subtitle2">
            Estamos procesando tu información, podrás ver tus productos en
            minutos.
          </span>
        </div>{' '}
        <ButtonPrimary
          className={classes.button}
          noDegraded
          text="Ir al Portal Financiero"
          onClick={() => {
            closeAddAccount();
          }}
        />
      </div>
    </>
  ) : (
    <section className={classes.allContainer}>
      <header className={classes.header}>
        <div>
          <ArrowBackIos style={{cursor: "pointer"}} onClick={() => closeAddAccount()} />
          <span className="title">
            <div>Agregar cuenta</div>{' '}
            <img src="https://storage.googleapis.com/rokinapp-images/finances/apiba.svg" />
          </span>
          <Close onClick={() => closeAddAccount()} />
        </div>
      </header>
      <section className={classes.containerBottom}>
        <div className={classes.flex}>
          <img key={selectBank?.id} src={selectBank?.icon} />
          <div>{selectBank?.name}</div>
        </div>
        <p className="subtitle">
          Introduce tus claves de acceso a {selectBank?.name}
        </p>
        <div className={classes.containerInputs}>
          <div className={classes.input}>
            <span>RUT</span>
            <RutInput
              label=""
              onChange={(e) => {
                setDataBank((prev: any) => ({
                  ...prev,
                  username: e.target.value as string,
                }));
              }}
              value={dataBank.username}
            />
          </div>
          <div className={classes.input}>
            <span>CLAVE</span>
            <PasswordInput
              label=""
              onChange={(e) =>
                setDataBank((prev: any) => ({
                  ...prev,
                  password: e.target?.value as string,
                }))
              }
              value={dataBank.password}
            />
          </div>{' '}
        </div>

        {error && (
          <div className={classes.error}>
            <img
              src={
                error === 'wrongCredentials'
                  ? ALERT_ICON
                  : error === 'connectionError'
                  ? CONNECTION_ICON
                  : ''
              }
            />
            <div>
              <span className="title">
                {error === 'wrongCredentials' && 'Credenciales incorrectas'}
                {error === 'connectionError' && 'error de conexión'}
              </span>
              <span className={'text'}>
                {error === 'wrongCredentials' &&
                  'RUT o clave incorrecta, llevas x intentos'}
                {error === 'connectionError' &&
                  'No pudimos procesar tu solicitud, vuelve a intentarlo más tarde'}
              </span>
            </div>
          </div>
        )}
        {/*    <p className={classes.disclaimerText}>
        Tus credenciales serán encriptadas y nadie tendrá acceso a ellas.
      </p> */}
        <ButtonText
          className={classes.buttonText}
          text={
            <>
              <img src={ESCUDO_SECURITY_ICON} />
              ¿Por qué te pedimos estas claves?{' '}
            </>
          }
          onClick={() => handleOpen()}
        />

        <Dialog
          open={openModal}
          onClose={handleClose}
          className={classes.dialog}
        >
          <div className={classes.containerModal}>
            <div className="pantalla">
              <div className={classes.topSection}>
                <div className="title">
                  <img src={ESCUDO_SECURITY_ICON} />
                  <span>¿Por qué te pedimos estas claves?</span>
                </div>
              </div>
              <div className={classes.separator}></div>
              <div className={classes.textSection}>
                <span className="text">
                  Te pedimos tus claves bancarias de acceso para leer tus
                  transacciones y entregarte nuestro servicio. Ojo, son{' '}
                  <strong>solo de consulta</strong>, no podemos realizar
                  transacciones por ti.
                </span>
              </div>
              <div className={classes.modalInfo}>
                <div className="info">
                  <img src={SECURITY_ICON} />
                  <span className="text2">
                    Tu <strong>usuario y claves están encriptados</strong> y son
                    estrictamente
                    <strong> confidenciales.</strong>
                  </span>
                </div>
                <div className="info">
                  <img src={EYE_ICON_SECUNDARY} />
                  <span className="text2">
                    Toda la información de tus cuentas es{' '}
                    <strong>solamente visible por tí</strong> en la plataforma.{' '}
                  </span>
                </div>
                <div className="info">
                  <img src={TARGET_ICON} />
                  <span className="text2">
                    El análisis y manejo de tu información se hace de{' '}
                    <strong>forma automática por algoritmos.</strong>
                  </span>
                </div>
              </div>
              <div className={classes.buttonSection}>
                <ButtonText text="Entendido" onClick={handleClose} />
              </div>
            </div>
          </div>
        </Dialog>
      </section>
      <div className={classes.containerButton}>
        <ButtonPrimary
          className={classes.primaryButton}
          text="Conectar"
          onClick={() => handleClick()}
          noDegraded
          disabled={isButtonDisabled}
        />
        <ButtonOutlined
          className={classes.primaryButton}
          text="Cancelar"
          onClick={() => closeAddAccount()}
        />
      </div>
    </section>
  );
};

export default AddDataAccount;
