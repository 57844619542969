import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme, { isDesktop: boolean }>((theme) => ({
  container: {
    marginTop: ({ isDesktop }) => (isDesktop ? "-4rem" : 0),
    [theme.breakpoints.down(1240)]: {
      position: "absolute",
      top: 0,
      width: "100%",
    },
  },
  containerTop: {
    background: theme.palette.primary.main,
    display: "flex",
    padding: ({ isDesktop }) =>
      isDesktop ? "6rem 1rem 3rem" : "2rem 1rem 3rem",
    alignItems: "center",
    gap: "2rem",
    color: "white",
    fontWeight: 300,
    fontFamily: "Montserrat",
    [theme.breakpoints.up(1280)]: {
      fontSize: "1.5rem",
      paddingLeft: "8rem",
      "& >span>span": {
        alignItems: "center",
        display: "flex",
        gap: "1rem",
      },
    },
    "& img": {
      width: "2.5rem",
      cursor: "pointer",
    },
  },
  containerChildren: {
    background: "#F4F3F3",
    borderRadius: "2rem 2rem 0 0",
    marginTop: "-2rem",
    [theme.breakpoints.up(1280)]: {
      padding: "4rem",
      background: "#F4F3F3",
    },
  },
}));
