import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<
  Theme,
  {
    isGraphic: boolean;
  }
>((theme) => ({
  graphicSpents: {
    width: '100%',
    padding: '0 0.1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    fontFamily: 'Montserrat',
    maxWidth: '27rem',
    [theme.breakpoints.down(1280)]: {
      margin: '0 auto',
    },
  },
  topGraphicSpents: {
    display: 'flex',
  },
  optionsTopGraphicSpents: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    '& .graphic': {
      cursor: 'pointer',
      padding: '0.5rem 0.5rem',
      display: 'flex',
      gap: '0.2rem',
      alignItems: 'center',
      borderRadius: '0.5rem 0rem 0 0.5rem',
      fontSize: '0.875rem',
      color: ({ isGraphic }) =>
        isGraphic ? `#54E5FF` : `rgba(277,277,277,0.5)`,
      textDecoration: ({ isGraphic }) => (isGraphic ? `underline` : ``),
      '& img': {
        width: '1rem',
      },
    },
    '& .table': {
      cursor: 'pointer',
      padding: '0.5rem',
      display: 'flex',
      gap: '0.2rem',
      fontSize: '0.875rem',
      alignItems: 'center',
      borderRadius: '0rem 0.5rem  0.5rem 0',
      color: ({ isGraphic }) =>
        !isGraphic ? `#54E5FF` : `rgba(277,277,277,0.5)`,
      textDecoration: ({ isGraphic }) => (!isGraphic ? `underline` : ``),
      '& img': {
        width: '1rem',
      },
    },
  },
  color: {
    '& >div,svg': {
      color: `rgba(277,277,277,0.5)`,
    },
  },
  graphic: {
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(1280)]: {
      width: '100%',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  viewAll: {
    color: 'white',
    textDecoration: 'underline',
    fontSize: '0.75rem',
    padding: '0 2rem',
    cursor: 'pointer',
  },
}));

export default useStyles;
