import { parseNumber } from '@utils';
import React from 'react';
import useStyles from './CardIndividualSavingRules.styles';

interface props {
  name?: string;
  amount?: number;
  disabled?: boolean;
  icon: string;
  type: string;
  amountThisMouth?: number;
  onClick?: () => void;
}

const CardIndividualSavingRules = ({
  name,
  amount,
  icon,
  type,
  amountThisMouth,
  onClick,
}: props) => {
  const classes = useStyles();
  return (
    <section className={classes.containerAll}>
      <div onClick={onClick} className={classes.containerTop}>
        <div className="left">
          <span className="title">{name}</span>
          <span className="amount">${parseNumber(amount || 0, 2)}</span>
          <span className="amountMonth">
            <span>+ ${parseNumber(amountThisMouth || 0, 2)}</span> este mes
          </span>
        </div>
        <div className="right">
          <img src={icon} />
          <span className="title">{type}</span>
        </div>
      </div>
    </section>
  );
};

export default CardIndividualSavingRules;
