import { ButtonPrimary } from '@components/Reusables';
import { Route } from '@interfaces';
import { navigate } from 'gatsby';

import { GRAPH_ASCENDING_ICON } from 'images/GCP';
import React from 'react';
import useStyles from './CompleteInvestments.styles';

interface props {
  amount?: number;
}

const CompleteInvestments = ({ amount }: props) => {
  const classes = useStyles();
  return (
    <section className={classes.containerAll}>
      <div className={classes.container}>
        <div className={classes.containerTop}>
          <span className="title">¡Configura tus inversiones!</span>
          <span className="subtitle">
            Debes completar tu perfil y crear un objetivo de inversión para
            comenzar a ahorrar de forma simple y personalizada.
          </span>
        </div>
        <div className={classes.buttons}>
          <ButtonPrimary
            text="Ir a configurar inversiones"
            startIcon={<img src={GRAPH_ASCENDING_ICON} />}
            onClick={() => {
              navigate(Route.investments);
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default CompleteInvestments;
