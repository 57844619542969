import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 1rem',
    gap: '1rem',
    fontWeight: 300,
    [theme.breakpoints.down(1240)]: {
      marginBottom: '1rem',
    },
    '& .topSection': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .cardVertical': {
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      background: '#00323C',
      borderRadius: '1rem',
      width: '47.5%',
      fontSize: '0.75rem',
      '& img': {
        width: '1.5rem',
        opacity: 0.5,
      },
      '& p': {
        opacity: 0.5,
      },
    },
    '& .cardHorizontal': {
      padding: '1rem',
      display: 'flex',
      gap: '1rem',
      background: 'rgba(0, 50, 60, 0.4)',
      borderRadius: '1rem',
      '& img': {
        width: '1.5rem',
      },
      '& p, span': {
        fontSize: '0.65rem',
        fontFamily: 'Montserrat',
      },
      '& h4': {
        textTransform: 'uppercase',
        fontFamily: 'Montserrat',
      },
    },
  },
  amount: {
    fontWeight: 'bold',
    fontSize: '1rem!important',
    fontFamily: 'Montserrat',
  },
  dataCard: {
    width: '100%',
  },
  amountCard: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
