import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  containerAll: {
    height: '100vh',
    position: 'fixed',
    zIndex: 999,
    top: 0,
  },
  background: {
    width: '100%',
    height: '100vh',
    background: 'rgba(0,0,0,0.6)',
    backdropFilter: 'blur(7px)',
    position: 'fixed',
    top: '0',
    zIndex: 1000,
  },
  options: {
    position: 'fixed',
    bottom: '0',
    zIndex: 9999,
    background: 'white',
    boxShadow: '4px 4px 20px 2px gray',
    borderRadius: '20px 20px 0 0',
    minHeight: '30rem',
    width: '100%',
    padding: '1.5rem 2.8rem 2.5rem',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '0.875rem',
    fontWeight: 300,
    color: '#282828',
    maxWidth: '18rem',
    margin: '0 auto',
    padding: '2rem 0 0',
  },
  closeModal: {
    width: '2rem',
    height: '0.25rem',
    background: 'rgba(0,0,0,0.5)',
    borderRadius: 10,
    margin: '0 auto 1rem',
  },
  selected: {
    width: '18rem',
    margin: '0 auto',
  },
  button: {
    width: '18rem',
    margin: '0 auto',
  },
  container: {
    width: '18rem',
    margin: '2rem auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  subtitle: {
    color: '#6b6b6b',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
  },
  buttonOtherBank: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#f4f3f3',
    padding: '1rem',
    borderRadius: 13,
    '& .flex': {
      flexDirection: 'row',
      textAlign: 'start',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& .grid': {},
    '& svg': {
      width: '1rem',
      color: '#6b6b6b',
    },
  },
  subsubtitle: {
    fontSize: '0.75rem',
    margin: '1rem 0',
    color: '#6b6b6b',
  },
  bankInput: {},
  buttonSend: {
    width: '16rem',
    margin: '1rem auto 0',
  },
}));
