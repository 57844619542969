import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "2rem 1rem",
    width: "100%",
    margin: "0 auto",
    fontFamily: "Montserrat",
    [theme.breakpoints.down(1280)]: {
      padding: "1rem",
    },
    [theme.breakpoints.up(1280)]: {
      padding: "2rem 2rem",
      boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
      borderRadius: "2rem",
    },
    "& .title": {
      fontWeight: "bold",
      display: "flex",
      gap: "1rem",
      marginLeft: "0.3rem",
      color: "#393E44",
    },
    "& .subtitle": {
      fontWeight: "bold",
      textTransform: "uppercase",
      fontSize: "0.875rem",
      padding: "1.5rem",
      textAlign: "start",
      color: theme.palette.text.secondary,
    },
    "& .subtitleCoolebra": {
      fontWeight: 300,
      textTransform: "math-auto",
      fontSize: "0.875rem",
      textAlign: "start",
      display: "block",
      color: theme.palette.text.secondary,
    },
  },
  bank: {
    display: "flex",
    cursor: "pointer",
    flexWrap: "wrap",
    justifyContent: "space-around",
    justifyItems: "center",
    alignItems: "center",
    alignContent: "center",
    [theme.breakpoints.up(1280)]: {
      justifyContent: "flex-start",
    },
  },
  iconFinerio: {
    width: "5rem",
  },
  bottomText: {
    color: theme.palette.text.secondary,
    fontWeight: 300,
    fontSize: "0.75rem",
    margin: "1rem 0 1rem 0.5rem",
    "& >div": {
      marginTop: "1rem",
      width: "100%",
    },
    "& span": {
      fontWeight: "bold",
    },
  },
  boton: {
    width: "100%",
  },
  success: {},
}));
