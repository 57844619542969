import { gql, useQuery } from "@apollo/client";
import { FinerioCredential } from "@interfaces";

export const GET_FINERIO_CREDENTIAL = gql`
  query FinerioCredentials {
    finerioCredentials {
      bankId
      enabled
      id
      finerioCredentialId
    }
  }
`;

interface QueryResult {
  finerioCredentials: FinerioCredential[];
}

interface HookReturn {
  finerioCredentials: FinerioCredential[];
  finerioCredentialLoading: boolean;
  refetchFinerioCredential: () => void;
}

export const getFinerioCredentials = (): HookReturn => {
  const { data, loading, refetch } = useQuery<QueryResult>(
    GET_FINERIO_CREDENTIAL
  );

  return {
    finerioCredentials: data?.finerioCredentials ?? [],
    finerioCredentialLoading: loading,
    refetchFinerioCredential: refetch,
  };
};
