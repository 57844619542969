import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import useStyles from '../Habits.style';
import { createManualAccount, getFinerioTransactions } from '@apollo';
import { finerioTransactionsByCategory } from '@utils';
import { getFinerioAccounts } from '@apollo';

import { currentUserPreferences } from '@apollo';
import { useApolloClient } from '@apollo/client';
import LayoutMyAccounts from '@components/MyAccounts/Layout/Layout';
import { FINERIO_ICON } from 'images/AWS/myAccount';
import AddAccountWithFinerio from '@components/MyAccounts/AddAccountWithFinerio/AddAccountWithFinerio';
import AddDataAccount from '@components/MyAccounts/AddDataAccount/AddDataAccount';
import { delay } from 'lodash';
import { LayoutOnBoarding } from '@components/Structural';

interface HabitsProps {
  setAddProduct: (v: boolean) => void;
  setShowSpinner: (v: boolean) => void;
}

const AddProduct = ({ setAddProduct, setShowSpinner }: HabitsProps) => {
  const isMobile = useMediaQuery('(max-width: 1280px)');
  const client = useApolloClient();
  const [isTransactions, setTransactions] = useState<boolean>(false);
  const classes = useStyles({ noTransaction: isTransactions });
  const [stepProduct, setStepProduct] = useState(0);
  const [selectBank, setSelectBank] = useState(0);
  const { finerioAccounts, refetchFinerioAccounts } = getFinerioAccounts();
  const { finerioTransactions } = getFinerioTransactions();
  const finerioData = finerioTransactionsByCategory(finerioTransactions);
  finerioData.reverse();

  //Closest date
  const temp = finerioData.map((d) => {
    return Math.abs(new Date() - new Date(d.date).getTime());
  });

  useEffect(() => {
    setTransactions(finerioTransactions.length !== 0);
  }, [finerioTransactions]);

  const refetchAndDelayAccounts = (time) => {
    delay(
      function (content) {
        refetchFinerioAccounts();
        setShowSpinner(false);
      },
      time,
      'ready to refetch'
    );
  };

  const showMessage = () => {
    setShowSpinner(true);
    refetchAndDelayAccounts(60000);
  };

  const handleNewAccount = (bankId: number) => {
    createManualAccount(client, { bankId: bankId });
    refetchAndDelayAccounts(1000);
    setAddProduct(false);
  };

  const closeAddAccount = () => {
    setStepProduct(0);
    setAddProduct(false);
  };

  return (
    <>
      {isMobile ? (
        <LayoutMyAccounts
          title={
            stepProduct === 0 ? (
              'AGREGAR UN PRODUCTO'
            ) : (
              <span className={classes.title}>
                Agregar cuenta con <img src={FINERIO_ICON} />
              </span>
            )
          }
          className={!isMobile ? classes.borderDesktop : 'Mobile'}
          onBackClick={() => {
            stepProduct === 0 ? setAddProduct(false) : setStepProduct(0);
          }}
          isDesktop
        >
          {stepProduct === 0 ? (
            <AddAccountWithFinerio
              finerioAccounts={finerioAccounts}
              setStepProduct={setStepProduct}
              setSelectBank={setSelectBank}
              handleNewAccount={handleNewAccount}
            />
          ) : (
            <AddDataAccount
              closeAddAccount={closeAddAccount}
              selectBank={selectBank}
              showMessage={showMessage}
            />
          )}
        </LayoutMyAccounts>
      ) : (
        <LayoutOnBoarding activeFinance>
          <LayoutMyAccounts
            title={
              stepProduct === 0 ? (
                'AGREGAR UN PRODUCTO'
              ) : (
                <span className={classes.title}>
                  Agregar cuenta con <img src={FINERIO_ICON} />
                </span>
              )
            }
            className={!isMobile ? classes.borderDesktop : 'Mobile'}
            onBackClick={() => {
              stepProduct === 0 ? setAddProduct(false) : setStepProduct(0);
            }}
            isDesktop
          >
            {stepProduct === 0 ? (
              <AddAccountWithFinerio
                finerioAccounts={finerioAccounts}
                setStepProduct={setStepProduct}
                setSelectBank={setSelectBank}
                handleNewAccount={handleNewAccount}
              />
            ) : (
              <AddDataAccount
                closeAddAccount={closeAddAccount}
                selectBank={selectBank}
                showMessage={showMessage}
              />
            )}
          </LayoutMyAccounts>
        </LayoutOnBoarding>
      )}
    </>
  );
};

export default AddProduct;
