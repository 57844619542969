import React, { useState } from 'react';
import { useStyles } from './MyExpenses.style';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import IncomeCard from '@components/Missions/Budget/M4/IncomeCard/IncomeCard';
import { Link } from '@reach/router';
import { Route } from '@interfaces';
import { currentUserPreferences } from '@apollo';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface MyExpensesProps {
  data: {
    name: string;
    total: number;
    amount: number;
    percentage: number;
    type: string;
    average: number;
  }[];
  isAll?: boolean;
}

const MyExpensesTable = ({ data, isAll = false }: MyExpensesProps) => {
  const classes = useStyles();
  const totalExpense = data?.categories
    .map((item) => item.expense)
    .reduce((a, b) => a + b);

  const orderData = data?.categories.sort((a, b) => b.expense - a.expense);
  const preferences = currentUserPreferences();
  const getBudgetGoal = (categoryName: string) => {
    const budgetGoal = preferences.budgetGoals?.find(
      (item) => Object.keys(item)[0] === categoryName
    );
    return budgetGoal ? budgetGoal[categoryName] : 0;
  };

  return (
    <section className={classes.table}>
      {orderData?.map((item, index) => {
        const porcentage = ((item.expense * 100) / totalExpense).toFixed(1);
        const valuePorcentage =
          porcentage !== null &&
          !isNaN(+porcentage) &&
          porcentage !== 'Infinity' &&
          porcentage
            ? Number(porcentage)
            : 0;

        return !isAll ? (
          index <= 4 && (
            <div key={index}>
              <Link
                to={Route.habitsDetails}
                state={{
                  name: item.name,
                  amount: item.expense,
                  total: totalExpense,
                  percentage: valuePorcentage,
                  transactions: item.transactions,
                }}
                key={item.name}
              >
                <div className={classes.item}>
                  <IncomeCard
                    name={item.name}
                    amount={item.expense}
                    total={totalExpense}
                    percentage={valuePorcentage}
                    type={'expense'}
                    goal={getBudgetGoal(item.name)}
                    icon={item.iconWhite}
                    color={item.color}
                  />
                </div>
              </Link>
            </div>
          )
        ) : (
          <div key={index}>
            <Link
              to={Route.habitsDetails}
              state={{
                name: item.name,
                amount: item.expense,
                total: totalExpense,
                percentage: valuePorcentage,
                transactions: item.transactions,
              }}
              key={item.name}
            >
              <div className={classes.item}>
                <IncomeCard
                  name={item.name}
                  amount={item.expense}
                  total={totalExpense}
                  percentage={valuePorcentage}
                  type={'expense'}
                  goal={getBudgetGoal(item.name)}
                  icon={item.iconWhite}  
                  color={item.color}
                  />
              </div>
            </Link>
          </div>
        );
      })}
    </section>
  );
};

export default MyExpensesTable;
