import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    borderRadius: '1rem 1rem 0 0',
    fontFamily: 'Montserrat',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& .top': {
      color: '#393E44',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      textTransform: 'uppercase',
    },
  },
  amount: {},
  arrow: {
    transform: 'rotate(270deg)',
    marginBottom: '0.5rem',
  },
  noProducts: {
    display: 'flex',
    gap: '0.5rem',
    '& .subtitle': {
      fontFamily: 'Montserrat',
      fontSize: '0.8rem',
      fontWeight: 'normal',
      color: '#393e44',
      opacity: '0.6',
    },
    '& .icon': {
      transform: 'rotate(-90deg)',
      color: '#0a5669',
    },
  },
  addIcon: {
    border: 'none',
    boxShadow: 'none',
    alignItems: 'center',
    '& button >span>span': {
      marginTop: '0.3rem',
    },
  },
  products: {
    display: 'grid',
    gap: '1.2rem',
    paddingBottom: '1rem',
    cursor: 'pointer',
    '& .autoAccounts': {
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      fontWeight: 500,
      textAlign: 'left',
      color: '#646464',
      '& span': {
        display: 'block',
        fontFamily: 'Nunito',
        fontSize: '0.75rem',
        fontWeight: 'normal',
        lineHeight: 1.17,
      },
    },
  },
  product: {
    padding: '1rem',
    display: 'flex',
    height: '3.5rem',
    gap: '1rem',
    background: '#fefefe',
    borderRadius: '1rem',
    alignItems: 'center',
    justifyContent: 'space-around',
    cursor: 'default',
    // shadow to the bottom and right
    boxShadow: '0 0.5rem 0.5rem rgba(0,0,0,0.1)',
  },
  productImg: {
    width: '1.5rem',
  },
  productInfo: {},
  check: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  checkToggle: {},
  link: {
    cursor: 'pointer',
    display: 'flex',
    gap: '1rem',
    width: '100%',
    marginLeft: '0.5rem',
    alignItems: 'center',
  },
  manualAccounts: {
    background: '#FEFEFE',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    borderRadius: 13,
    padding: '1.5rem 1.8rem',
  },
  manualProduct: {
    display: 'flex',
    width: '100%',
    height: '3.5rem',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'default',
  },
  bankNameAndImg: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  separator: {
    height: 'inherit',
    width: '100%',
    borderBottom: '0.1rem solid #D6D6D6',
  },
  containerTop: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    '& h4': {
      textTransform: 'uppercase',
      color: '#282828',
      fontWeight: 400,
    },
  },
}));
