import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "22rem",
    "& #transporte": {
      background: "red",
    },
    "& .title": {
      position: "absolute",
      top: "7rem",
      left: "7rem",
      fontSize: "1.7rem",
      textAlign: "center",
      fontWeight: "bolder",
      color: theme.palette.text.primary,
      "& span": {
        fontSize: "1.2rem",
        fontWeight: 400,
      },
    },
    "& #id1": {
      background: "red",
      padding: "5rem",
    },
  },
  item: {
    margin: "0.5rem 0",
  },
  top: {
    "& .title": {
      fontWeight: "bold",
      display: "block",
    },
    "& .subtitle": {
      fontSize: "0.87rem",
    },
  },
  table: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
}));

export { useStyles };