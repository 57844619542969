import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import HeaderFinancialPortal from '@components/FinancialPortal/Header/Header';
import { LayoutOnBoarding, SEO } from '@components/Structural';
import { makeStyles, Theme } from '@material-ui/core';
import { Calendar } from '@components/Finance';
import { MyAccountsPage } from '@components/FinancialPortal/MyAccount';
import MyHabits from '@components/FinancialPortal/Habits/Habits';
import { LayoutMobileInvestments } from '@components/Investments/Layout';
import { CloseRounded } from '@material-ui/icons';
import { ButtonPrimary } from '@components';
import {
  CALENDAR_ICON_GREEN,
  FINANCES_ICON_GREEN,
  LINK_ICON_GREEN,
  MONITOR_ICON_GREEN,
} from 'images/GCP';
import { useAuth } from '@hooks';
import SmartSave from '@components/SmartSave/Dashboard/dashboard';
import { updateFinerioAccount } from '@apollo';
import { useApolloClient } from '@apollo/client';

const FinancialPortal = () => {
  useAuth({ redirectIfNotLogged: true });

  const isMobile = useMediaQuery('(max-width: 1240px)');
  const classes = useStyles();
  const client = useApolloClient();
  const [isUpdatingAccount, setUpdatingAccount] = useState<boolean>(false);

  useEffect(() => {
    updateFinerioAccount(client)
      .then((resp) => {
        console.log('🚀 « resp:', resp);
        if (resp) {
          setUpdatingAccount(true);
        } else {
          setUpdatingAccount(false);
        }
      })
      .catch((error) => {
        setUpdatingAccount(false);
      });
  }, []);

  const [isSelect, setSelect] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  const [toggleUseEffect, setToggleUseEffect] = useState(false);

  useEffect(() => {
    const location = window.location.href.split('#')[1];
    location === 'expenses' && setSelect(1);
  }, [isSelect]);

  const togglePage = () => {
    setToggleUseEffect(!toggleUseEffect);
  };

  const infoButtonHandler = () => {
    //OPEN POPUP
    setOpenInfoPopUp(!openInfoPopUp);
  };

  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);
  const [isTransactions, setTransactions] = useState<boolean>(false);

  return (
    <>
      <SEO
        title="Administra tus finanzas personales - Rokin"
        description="Organiza tu plata, controla tus gastos, maneja tus deudas y potencia tu ahorro todo desde un solo lugar."
      />
      {!isMobile ? (
        <LayoutOnBoarding activeAccount>
          <HeaderFinancialPortal
            background={isSelect === 1 ? '#00323c' : ''}
            className={isTransactions ? classes.backgroundColor : ''}
            setSelect={setSelect}
            isSelect={isSelect}
            infoPopup={infoButtonHandler}
            which={'financialPortal'}
          >
            {isSelect === 0 ? (
              <div
                className={
                  classes.myAccountsContainer +
                  ' ' +
                  (fullScreen ? classes.fullScreen : '')
                }
              >
                <MyAccountsPage
                  fullScreen={fullScreen}
                  setFullScreen={setFullScreen}
                  isTransactions={isTransactions}
                  setTransactions={setTransactions}
                  isUpdatingAccount={isUpdatingAccount}
                />
              </div>
            ) : isSelect === 1 ? (
              <div className={classes.container}>
                <MyHabits togglePage={togglePage} />
              </div>
            ) : (
              <SmartSave />
            )}
          </HeaderFinancialPortal>
        </LayoutOnBoarding>
      ) : (
        <LayoutMobileInvestments activeAccount>
          <HeaderFinancialPortal
            setSelect={setSelect}
            isSelect={isSelect}
            infoPopup={infoButtonHandler}
          >
            {isSelect === 0 ? (
              <div
                className={
                  classes.myAccountsContainer +
                  ' ' +
                  (fullScreen ? classes.fullScreen : '')
                }
              >
                <MyAccountsPage
                  fullScreen={fullScreen}
                  setFullScreen={setFullScreen}
                  isTransactions={isTransactions}
                  setTransactions={setTransactions}
                  isUpdatingAccount={isUpdatingAccount}
                />
              </div>
            ) : isSelect === 1 ? (
              <div className={classes.container}>
                <MyHabits togglePage={togglePage} />
              </div>
            ) : (
              <SmartSave />
            )}
          </HeaderFinancialPortal>
        </LayoutMobileInvestments>
      )}

      <div
        className={
          openInfoPopUp ? classes.popUpContainer : classes.hiddenDisplay
        }
      >
        <div className={classes.outerBorder}>
          <div className={classes.popUp}>
            <div className={classes.itemRow}>
              <img src={FINANCES_ICON_GREEN} alt="" />
              <h3 className={classes.rokipediaPopUpTitle}>Mis Finanzas</h3>
              <button onClick={infoButtonHandler}>
                <CloseRounded htmlColor="#393E44" />
              </button>
            </div>
            <div className={classes.popUpVector}></div>

            <p className={classes.subtitle}>
              Administra tu dinero de la mejor forma en "Mis Finanzas":
            </p>

            {/*<div className={classes.textButtonContainer}>
              <img src={LINK_ICON_GREEN} alt="" className={classes.image} />
              <p className={classes.subtext}>
                <strong className={classes.strongText}>
                  Sincroniza tus productos:{" "}
                </strong>
                <br />
                Cuentas bancarias, tarjetas, ¡todo en un solo lugar!
              </p>
      </div>*/}
            <div className={classes.textButtonContainer}>
              <img src={MONITOR_ICON_GREEN} alt="" className={classes.image2} />
              <p className={classes.subtext}>
                <strong className={classes.strongText}>
                  Conoce y monitorea tus gastos:{' '}
                </strong>
                <br />
                Sabrás exactamente cuánto gastas y en qué lo gastas. Crea
                presupuestos para tus categorías de gasto y ahorra fácilmente.
              </p>
            </div>
            <div className={classes.textButtonContainer}>
              <img
                src={CALENDAR_ICON_GREEN}
                alt=""
                className={classes.image2}
              />
              <p className={classes.subtext}>
                <strong className={classes.strongText}>
                  Crea recordatorios de tus ingresos y gastos recurrentes en el
                  calendario:
                </strong>
                <br />
                ¡Nunca más se te pasará la fecha de vencimiento de la tarjeta!
              </p>
            </div>

            <div className={classes.buttonGotItContainer}>
              <ButtonPrimary
                className={classes.buttonGotIt}
                onClick={() => {
                  infoButtonHandler();
                }}
                text="Entendido"
                secondaryVersion
                noDegraded
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialPortal;

const useStyles = makeStyles<Theme>((theme) => ({
  container: {},
  myAccountsContainer: {
    [theme.breakpoints.down(1240)]: {
      marginTop: '1rem',
      paddingTop: '1rem',
      background: '#008195',
    },
  },
  fullScreen: {
    position: 'fixed',
    width: '100%',
    // height: "150%",
    zIndex: 1000,
    margin: 0,
    backgroundColor: '#F4F4F3',
    top: 0,
    bottom: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  backgroundColor: {
    background: '#00323c',
  },
  popUpContainer: {
    zIndex: 1000,
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.8)',
    height: '100rem',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '10%',
    [theme.breakpoints.down(1240)]: {
      paddingTop: '35%',
    },
  },
  outerBorder: {
    width: '43.938rem',
    height: '21rem',
    borderRadius: '40px',
    border: '1px solid #FFFFFF',
    padding: '0.25rem',
    [theme.breakpoints.down(1240)]: {
      width: '21.063rem',
      height: '30.188rem',
    },
  },
  popUp: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '35px',
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    [theme.breakpoints.down(1240)]: {
      borderRadius: '35px',
    },
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  popUpVector: {
    width: '100%',
    height: '0.125rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '2px',
    margin: '0.7rem 0 0 0',
    [theme.breakpoints.down(1240)]: {},
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontSize: '0.875rem',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '1.43',
    color: '#393E44',
    [theme.breakpoints.down(1240)]: {
      marginTop: '1rem',
    },
  },
  subtext: {
    fontFamily: 'Noto Sans, Montserrat',
    fontSize: '0.75rem',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '1.67',
    color: '#393E44',
    margin: '1.2rem 0 0 0',
  },
  buttonGotItContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(1240)]: {
      margin: '1.5rem 0 0 0',
    },
  },
  buttonGotIt: {
    margin: '1.2rem 0 0 0',
    width: '3rem',
  },
  textButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  rokipediaPopUpTitle: {
    fontFamily: 'Nunito, Montserrat',
    fontSize: '1rem',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 'normal',
    color: '#393E44',
    margin: '0 70% 0 0 ',
    [theme.breakpoints.down(1240)]: {
      margin: '0 30% 0 3% ',
    },
  },
  image: {
    margin: '1.5rem 1rem 0 0',
    [theme.breakpoints.down(1240)]: {
      margin: '0.7rem 1rem 0 0',
    },
  },
  image2: {
    margin: '0.7rem 1rem 0 0',
    [theme.breakpoints.down(1240)]: {
      margin: '-1.6rem 1rem 0 0',
    },
  },
  strongText: {
    fontWeight: 'bolder',
    color: 'black',
  },
  hiddenDisplay: {
    display: 'none',
  },
}));
