import { ButtonPrimary, Select } from '@components/Reusables';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useStyles } from './ConnectedBank.styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TextInput } from '@components/Inputs';
import AddDataAccount from '@components/MyAccounts/AddDataAccount/AddDataAccount';
import { CHILE_BANK_ICON, SANTANDER_BANK_ICON } from 'images/AWS/myAccount';
import { FinerioBankInterface } from '@interfaces';
import { finerioListBank } from '@data';
import { getFinerioAccounts } from '@apollo';

interface propsCreate {
  closeMenu: () => void;
  showMessage: () => void;
  setFullScreen?: Dispatch<SetStateAction<boolean>>;
}

const ConnectedBank = ({ closeMenu, showMessage, setFullScreen }: propsCreate) => {
  const classes = useStyles();
  const [bank, setBank] = useState<FinerioBankInterface>();
  const [otherBank, setOtherBank] = useState(false);
  const [bankInput, setBankInput] = useState<string>();
  const [bankSelected, SetBankSelected] = useState<boolean>();
  const { finerioAccounts } = getFinerioAccounts();

  const availableBanks = finerioListBank.filter((item) => item.apiba === true)

  const banksOptions = availableBanks.map((item) => { 
    const isConnected = finerioAccounts.find((finAcc) => finAcc.bankId == String(item.finerioBankId))


    return {
      name: item.name,
      value: item.finerioBankId,
      disabled: isConnected ? true : false
    }
  })

  useEffect(() => { 
    setFullScreen(bankSelected ?? false)
  }, [bankSelected])

  return !bankSelected ? (
    <section className={classes.containerAll}>
      <section onClick={closeMenu} className={classes.background} />
      <section className={classes.options}>
        <div onClick={closeMenu} className={classes.closeModal} />

        <div className={classes.title}>
          Conecta tus bancos para ver toda tu plata en un mismo lugar, y llevar
          un registro automático de tus gasto
        </div>
        <div>
          <div className={classes.container}>
            <div className={classes.subtitle}>Selecciona tu banco</div>
            <Select
              label="Banco"
              className={classes.selected}
              name="input"
              value={bank?.finerioBankId}
              onChange={(e) => setBank(availableBanks.find((item :FinerioBankInterface) => item.finerioBankId === e.target.value))}
              options={banksOptions}
            />
            <ButtonPrimary
              className={classes.button}
              text="Continuar"
              disabled={!bank}
              onClick={() => {
                SetBankSelected(true);
              }}
            />{' '}
            {/* <div className={classes.buttonOtherBank}>
              <div className="flex">
                <div
                  className={classes.subtitle}
                  onClick={() => setOtherBank(!otherBank)}
                >
                  ¿NO está tu banco?
                </div>
                {!otherBank ? (
                  <ArrowForwardIosIcon
                    onClick={() => setOtherBank(!otherBank)}
                  />
                ) : (
                  <> </>
                )}{' '}
              </div>
              {otherBank && (
                <div>
                  <div className={classes.subsubtitle}>
                    Si no ves tu banco, ingresa a tu correo para que te
                    notifiquemos cuando lo tengamos
                  </div>
                  <div className="grid">
                    <div className={classes.subtitle}>¿Cuál es tu banco?</div>
                    <TextInput
                      value={bankInput}
                      label="Nombre banco"
                      className={classes.bankInput}
                      onChange={(e) => setBankInput(e.target.value as string)}
                    />
                  </div>
                  <ButtonPrimary
                    className={classes.buttonSend}
                    text="Enviar"
                    onClick={() => {}}
                  />
                </div>
              )}
            </div> */}
          </div>
        </div>
      </section>
    </section>
  ) : (
    <AddDataAccount
      selectBank={bank}
      closeAddAccount={() => SetBankSelected(false)}
      showMessage={() => showMessage()}
    />
  );
};

export default ConnectedBank;
