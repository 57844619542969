import { ApolloClient } from "@apollo/client";
import { FinerioBankRequestedInterface } from "@interfaces";
import { gql } from "@apollo/client";


const CREATE_FINERIO_BANK_REQUESTED = gql`
mutation CreateFinerioBankRequested($bankRequested: String!) {
    createFinerioBankRequested(bankRequested: $bankRequested) {
      bankRequested
      id
    }
  }
`;

type CreateFinerioBankRequestedParams = {
    bankRequested: string;
};

type CreateFinerioBankRequestedResult = {
    createFinerioBankRequested: FinerioBankRequestedInterface;
};

export async function createFinerioBankRequested(
    client: ApolloClient<any>,
    params: CreateFinerioBankRequestedParams
): Promise<CreateFinerioBankRequestedResult> {
    const { data } = await client.mutate({
        mutation: CREATE_FINERIO_BANK_REQUESTED,
        variables: params,
    });
    return data;
}

